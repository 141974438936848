<template>
  <div class="component firebaseui-auth">
    <section id="firebaseui-auth-container"></section>
    <section id="firebaseui-auth-container-footer">
      <div class="firebaseui-card-footer firebaseui-provider-sign-in-footer">
        <p class="firebaseui-tos firebaseui-tospp-full-message">
          By continuing, you are indicating that you accept our
          <a
            @click="displayTos()"
            class="firebaseui-link firebaseui-tos-link"
            target="_blank"
            >Terms of Service</a
          >
          and
          <a
            @click="displayPrivacy()"
            class="firebaseui-link firebaseui-pp-link"
            target="_blank"
            >Privacy Policy</a
          >.
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import firebase from "firebase";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
export default {
  name: "GoogleFirebaseOAuth",
  props: ["url"],
  mounted() {
    // https://github.com/firebase/firebaseui-web#signinsuccesswithauthresultauthresult-redirecturl

    let ui = firebaseui.auth.AuthUI.getInstance();
    if (!ui) {
      ui = new firebaseui.auth.AuthUI(firebase.auth());
    }
    let uiConfig = {
      signInSuccessUrl: this.url,
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        //firebase.auth.FacebookAuthProvider.PROVIDER_ID
      ],
      callbacks: {
        signInSuccessWithAuthResult: (/*authResult*/) => {
          // this.$emit('updateAuthResult', authResult)
          // store.commit(isClient)

          //console.log(authResult);

          /*
          
            authResult.additionalUserInfo.profile.email
            authResult.additionalUserInfo.profile.family_name
            authResult.additionalUserInfo.profile.given_name
            authResult.additionalUserInfo.profile.name
            authResult.additionalUserInfo.profile.picture
            authResult.additionalUserInfo.profile.verified_email
            authResult.additionalUserInfo.credential.accessToken
            authResult.additionalUserInfo.credential.idToken
            authResult.additionalUserInfo.user

            */
          return true;
        },
      },
    };
    ui.start("#firebaseui-auth-container", uiConfig);
  },
  methods: {
    displayPrivacy() {
      this.$emit("open-privacy");
    },
    displayTos() {
      this.$emit("open-tos");
    },
  },
};
</script>

<style lang="scss" scoped>
.firebaseui-auth {
}
</style>