<template>
  <div class="model page">
    <section class="section">
      <breadcrumb :path="[{ url: '/policy', name: 'policy' }]" />
      <h1 class="title">policy</h1>

      <p>
        Please read <a @click="openPrivacy()">privacy terms</a> and
        <a @click="openTos()">terms of service</a>.
      </p>
    </section>
    <policy-overlay
      ref="policyOverlay"
      is_active="policy_open"
      default_view="privacy"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Breadcrumb from "../components/Breadcrumb.vue";
import PolicyOverlay from "../components/PolicyOverlay.vue";

export default {
  name: "Policy",
  components: { PolicyOverlay, Breadcrumb },
  data() {
    return {
      model_path_id_latest: null,
      option_drive: false,
      policy_open: false,
    };
  },
  computed: {
    ...mapGetters(["getUser", "isUserAuth"]),
  },
  methods: {
    openPrivacy() {
      console.log(1);
      this.$refs.policyOverlay.active = true;
    },
    displayTos() {},
  },
  mounted() {
    // open policy
    this.policy_open = true;

    if (typeof process.env.VUE_APP_OPTION_DRIVE !== "undefined") {
      this.option_drive =
        process.env.VUE_APP_OPTION_DRIVE == "true" ? true : false;
    }

    try {
      this.model_path_id_latest = localStorage.getItem("model_path_id_latest");
    } catch {
      console.log("localStorage error");
    }
  },
};
</script>


<style lang="scss" scoped>
@import "../scss/main.scss";
</style>