<template>
  <div class="model page">
    <section class="section">
      <breadcrumb :path="[{ url: '/tos', name: 'tos' }]" />
      <h1 class="title">terms of service</h1>

      <p>
        Please read <a @click="openTos()">terms of service</a> and
        <a @click="openPrivacy()">privacy terms</a>.
      </p>
    </section>
    <policy-overlay
      ref="policyOverlay"
      is_active="policy_open"
      default_view="tos"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Breadcrumb from "../components/Breadcrumb.vue";
import PolicyOverlay from "../components/PolicyOverlay.vue";

export default {
  name: "Tos",
  components: { PolicyOverlay, Breadcrumb },
  data() {
    return {
      model_path_id_latest: null,
      option_drive: false,
      policy_open: false,
    };
  },
  computed: {
    ...mapGetters(["getUser", "isUserAuth"]),
  },
  methods: {
    openPrivacy() {
      this.$refs.policyOverlay.active = true;
      this.$refs.policyOverlay.load("privacy");
    },
    openTos() {
      this.$refs.policyOverlay.active = true;
      this.$refs.policyOverlay.load("tos");
    },
    displayTos() {},
  },
  mounted() {
    // open policy
    this.policy_open = true;

    if (typeof process.env.VUE_APP_OPTION_DRIVE !== "undefined") {
      this.option_drive =
        process.env.VUE_APP_OPTION_DRIVE == "true" ? true : false;
    }

    try {
      this.model_path_id_latest = localStorage.getItem("model_path_id_latest");
    } catch {
      console.log("localStorage error");
    }
  },
};
</script>


<style lang="scss" scoped>
@import "../scss/main.scss";
</style>