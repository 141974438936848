<template>
  <div
    :class="{ 'policy-overlay': true, 'is-active': active }"
    id="policy-overlay"
    @click.self="close()"
  >
    <div
      class="policy-overlay__close-button"
      id="close-button"
      @click="close()"
    ></div>

    <div
      class="policy-overlay__inner policy-overlay__inner--scroll"
      id="policy-overlay__inner"
    >
      <div class="policy-overlay__header">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-link-2"
        >
          <path
            d="M15 7h3a5 5 0 0 1 5 5 5 5 0 0 1-5 5h-3m-6 0H6a5 5 0 0 1-5-5 5 5 0 0 1 5-5h3"
          ></path>
          <line x1="8" y1="12" x2="16" y2="12"></line>
        </svg>
        You and Us
      </div>

      <div class="policy-overlay__tabs">
        <ul>
          <li
            id="overlay-button-tos"
            @click="load('tos')"
            :class="{
              noselect: true,
              'is-active': policy_category == 'tos',
            }"
          >
            terms of service
          </li>
          <li
            id="overlay-button-privacy"
            @click="load('privacy')"
            :class="{
              noselect: true,
              'is-active': policy_category == 'privacy',
            }"
          >
            privacy
          </li>
          <li
            id="overlay-button-cookie"
            @click="load('cookie')"
            :class="{
              noselect: true,
              'is-active': policy_category == 'cookie',
            }"
          >
            cookie
          </li>
          <li
            id="overlay-button-disclaimer"
            @click="load('disclaimer')"
            :class="{
              noselect: true,
              'is-active': policy_category == 'disclaimer',
            }"
          >
            disclaimer
          </li>
        </ul>
      </div>

      <div class="policy-overlay__wrapper">
        <a href="#bottom">
          <div class="scroll-down"></div>
        </a>

        <div class="policy-overlay__content" id="overlay-content">
          <div style="text-align: center; color: #000">please select</div>
        </div>

        <div class="policy-overlay__bottom" id="bottom">
          end of document -
          <span
            style="cursor: pointer"
            onclick="javascript:document.querySelector('.policy-overlay__inner--scroll').scrollTop = 0"
            >back to top</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PolicyOverlay",
  data() {
    return {
      active: false,
      policy_category: "",
    };
  },
  props: ["is_active", "default_view"],
  mounted() {
    if (
      this.is_active &&
      this.default_view &&
      (this.default_view == "tos" ||
        this.default_view == "privacy" ||
        this.default_view == "cookie" ||
        this.default_view == "disclaimer")
    ) {
      this.load(this.default_view);
    }
    this.active = this.is_active;
  },
  methods: {
    close() {
      this.active = false;
    },
    async load(page) {
      this.policy_category = page;
      let content = document.getElementById("overlay-content");
      content.innerHTML = await (
        await fetch(window.location.origin + "/html/" + page + ".html")
      ).text();
    },
  },
};
</script>

<style lang="scss" scoped>
.policy-overlay {
  //overscroll-behavior: contain;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: -1;

  &--login {
    z-index: -1;
    background: rgba(0, 0, 0, 0.5);
    text-align: center;
    visibility: hidden;

    .policy-section__signin {
      padding: 5%;
    }

    &.is-active {
      z-index: 99;
      opacity: 1;
      visibility: visible;
    }
  }

  -webkit-transform: scale(1.8);
  -moz-transform: scale(1.8);
  -o-transform: scale(1.8);
  transform: scale(1.8);
  backdrop-filter: blur(4px);
  transition: all 500ms;

  svg {
    max-height: 15px;
    margin: 0 auto;
  }

  &.is-active {
    z-index: 100;
    opacity: 1;
    visibility: visible;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }

  &__header {
    text-transform: uppercase;
    text-align: center;
    padding: 20px;
    margin-bottom: 10px;
    font-weight: 600;
  }

  &__inner {
    color: #000;
    max-width: 50%;
    max-height: 80%;
    overflow-y: auto;
    margin: 80px auto;
    background-color: #fff;
    padding: 1%;
    font-size: 0.8rem;
    scroll-behavior: smooth;
    z-index: 101;
    @media (max-width: 790px) {
      max-width: 100%;
    }
  }

  &__tabs {
    margin-bottom: 6px;

    ul {
      width: 100%;
      display: inline-flex;
      text-transform: uppercase;

      li + li {
        border-left: 1px solid #000;
      }

      li {
        width: 25%;
        text-align: center;
        cursor: pointer;
        transition: all 1s;
        padding: 4px;

        &:hover,
        &.is-active {
          background-color: #000;
          color: #fff;
        }
      }
    }
  }

  &__content {
    //margin-top:60px;
    padding: 60px 20px;
    white-space: pre-wrap;
    //border-top: 1px dashed #e2e2e2;
    text-align: justify;

    h2 {
      font-weight: 600;
      font-size: 1rem;
      text-transform: uppercase;
    }

    h3 {
      font-weight: 600;
      text-transform: uppercase;
      //font-size: 1rem;
    }
  }

  &__close-button {
    $size: 50px;
    height: $size;
    width: $size;
    position: absolute;
    cursor: pointer;

    top: 1%;
    right: 1%;

    box-sizing: border-box;
    line-height: $size;
    display: inline-block;

    &:before,
    &:after {
      $width: 50px;
      $height: 10px;
      transform: rotate(-45deg);
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -$height/2;
      margin-left: -$width/2;
      display: block;
      height: $height;
      width: $width;
      background-color: #fff;
      transition: all 0.25s ease-out;
    }

    &:after {
      transform: rotate(-135deg);
    }

    &:hover {
      &:before,
      &:after {
        transform: rotate(0deg);
      }
    }
  }

  &__wrapper {
    position: relative;
    max-height: 80%;

    .scroll-down {
      height: 50px;
      width: 30px;
      border: 2px solid rgba(0, 0, 0, 1);
      position: relative;
      left: calc(50% - 15px);
      top: 20px;
      border-radius: 50px;
      cursor: pointer;
      opacity: 1;
    }
    .scroll-down::before,
    .scroll-down::after {
      content: "";
      position: absolute;
      top: 20%;
      left: 50%;
      height: 10px;
      width: 10px;
      transform: translate(-50%, -100%) rotate(45deg);
      border: 1px solid black;
      border-top: transparent;
      border-left: transparent;
      animation: scroll-down 1s ease-in-out infinite;
    }
    .scroll-down::before {
      top: 30%;
      animation-delay: 0.3s;
      /* animation: scroll-down 1s ease-in-out infinite; */
    }

    @keyframes scroll-down {
      0% {
        /* top:20%; */
        opacity: 0;
      }
      30% {
        opacity: 1;
      }
      60% {
        opacity: 1;
      }
      100% {
        top: 90%;
        opacity: 0;
      }
    }
  }

  &__bottom {
    text-align: center;
    font-size: 0.5rem;
    text-transform: uppercase;
    border-top: 1px solid #9e9e9e;
    padding: 8px;
  }
}
</style>