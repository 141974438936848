<template>
  <div class="model page">
    <section class="section">
      <breadcrumb :path="[{ url: '/login', name: 'login' }]" />

      <h1 class="title">login</h1>
      <google-firebase-o-auth
        @open-privacy="openPrivacy"
        @open-tos="openTos"
        url="/"
      ></google-firebase-o-auth>
    </section>

    <abstraktion-footer />

    <policy-overlay
      ref="policyOverlay"
      :is_active="false"
      default_view="privacy"
    />
  </div>
</template>

<script>
import AbstraktionFooter from "../components/AbstraktionFooter.vue";
import Breadcrumb from "../components/Breadcrumb.vue";
import GoogleFirebaseOAuth from "../components/GoogleFirebaseOAuth.vue";
import PolicyOverlay from "../components/PolicyOverlay.vue";
export default {
  components: {
    GoogleFirebaseOAuth,
    AbstraktionFooter,
    PolicyOverlay,
    Breadcrumb,
  },
  mounted() {},
  methods: {
    openPrivacy() {
      this.$refs.policyOverlay.load("privacy");
      this.$refs.policyOverlay.active = true;
    },
    openTos() {
      this.$refs.policyOverlay.load("tos");
      this.$refs.policyOverlay.active = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.section {
  min-height: calc(100vh - 187px);
}
</style>
