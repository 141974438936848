var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'policy-overlay': true, 'is-active': _vm.active },attrs:{"id":"policy-overlay"},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.close()}}},[_c('div',{staticClass:"policy-overlay__close-button",attrs:{"id":"close-button"},on:{"click":function($event){return _vm.close()}}}),_c('div',{staticClass:"policy-overlay__inner policy-overlay__inner--scroll",attrs:{"id":"policy-overlay__inner"}},[_c('div',{staticClass:"policy-overlay__header"},[_c('svg',{staticClass:"feather feather-link-2",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('path',{attrs:{"d":"M15 7h3a5 5 0 0 1 5 5 5 5 0 0 1-5 5h-3m-6 0H6a5 5 0 0 1-5-5 5 5 0 0 1 5-5h3"}}),_c('line',{attrs:{"x1":"8","y1":"12","x2":"16","y2":"12"}})]),_vm._v(" You and Us ")]),_c('div',{staticClass:"policy-overlay__tabs"},[_c('ul',[_c('li',{class:{
            noselect: true,
            'is-active': _vm.policy_category == 'tos',
          },attrs:{"id":"overlay-button-tos"},on:{"click":function($event){return _vm.load('tos')}}},[_vm._v(" terms of service ")]),_c('li',{class:{
            noselect: true,
            'is-active': _vm.policy_category == 'privacy',
          },attrs:{"id":"overlay-button-privacy"},on:{"click":function($event){return _vm.load('privacy')}}},[_vm._v(" privacy ")]),_c('li',{class:{
            noselect: true,
            'is-active': _vm.policy_category == 'cookie',
          },attrs:{"id":"overlay-button-cookie"},on:{"click":function($event){return _vm.load('cookie')}}},[_vm._v(" cookie ")]),_c('li',{class:{
            noselect: true,
            'is-active': _vm.policy_category == 'disclaimer',
          },attrs:{"id":"overlay-button-disclaimer"},on:{"click":function($event){return _vm.load('disclaimer')}}},[_vm._v(" disclaimer ")])])]),_vm._m(0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"policy-overlay__wrapper"},[_c('a',{attrs:{"href":"#bottom"}},[_c('div',{staticClass:"scroll-down"})]),_c('div',{staticClass:"policy-overlay__content",attrs:{"id":"overlay-content"}},[_c('div',{staticStyle:{"text-align":"center","color":"#000"}},[_vm._v("please select")])]),_c('div',{staticClass:"policy-overlay__bottom",attrs:{"id":"bottom"}},[_vm._v(" end of document - "),_c('span',{staticStyle:{"cursor":"pointer"},attrs:{"onclick":"javascript:document.querySelector('.policy-overlay__inner--scroll').scrollTop = 0"}},[_vm._v("back to top")])])])}]

export { render, staticRenderFns }