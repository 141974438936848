<template>
  <nav class="component breadcrumb" aria-label="breadcrumbs">
    <ul>
      <li>
        <a><router-link to="/">Home</router-link></a>
      </li>
      <li v-for="link in path" :key="link.name">
        <span>{{ link.name }}</span>
        <!-- <a
          ><router-link :to="link.url">{{ link.name }}</router-link></a
        > -->
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "Breadcrumb",
  props: ["path"],
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.breadcrumb {
  text-transform: uppercase;
  li span {    align-items: center;
    color: #000;
    display: flex;
    justify-content: center;
    padding: 0 0.75em;
  }
}
</style>